@import "@styles/breakpoints.module.scss";

.closeIcon {
  transform: scale(1.25);
  position: absolute;
  top: -2.25rem;
  right: 0;
  fill: #ffffff;
  cursor: pointer;
}

.container {
  background-color: #ffffff;
  max-width: 29rem;
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
}

.container > div {
  margin-bottom: 1.5rem;
}

.header {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: ($breakpoint-medium - 1)) {
  .modal {
    border-radius: 8px;
    width: 464px;
  }
}

.container.rtl {
  direction: rtl;
}
