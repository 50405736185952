@import "@styles/breakpoints.module.scss";

.frame {
  width: 100%;
  padding: 0.25rem;
  background-color: white;
  box-shadow: 0 0.0625rem 0.5rem 0 rgb(55 57 70 / 8%);
  border-radius: 0.25rem;

  &.loading {
    background-color: #f4f2f7;
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .frame {
    box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  }
}

.container {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 100%;

  a:hover {
    cursor: pointer;
  }

  .image {
    object-fit: contain;
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
  }
}
