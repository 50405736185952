@import "@styles/breakpoints.module.scss";
@import "@styles/hideScrollbar.module.scss";

.section {
  .title {
    color: #27242c;
    font-size: 1.25rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: bold;
    }
  }

  .seeAll {
    color: #e75e40;
    cursor: pointer;
    font-size: 1rem;
  }
}

.categoriesSection {
  min-height: 23.9rem;

  .categoriesTabs {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-auto-flow: column;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      border-bottom: 2px solid #f2f2f2;
    }
  }

  .categoryStoresContainer {
    overflow-x: auto;
    @include hideScrollbar;
  }

  .categoryStores {
    padding-top: 1rem;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 100%;
  }

  &.isMobile {
    min-height: 24.9rem;

    .categoryStoresContainer {
      padding: 0 1rem;
      margin: 0 -1rem;
    }

    .categoryStores {
      padding: 1rem 1rem;
      margin: 0 -1rem;
    }

    .categoriesTabs:before {
      left: 1rem;
      right: 1rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  .categoriesSection {
    .categoriesTabs:before {
      left: 2rem;
      right: 2rem;
    }

    .categoryStoresContainer {
      padding: 0 2rem;
      margin: 0 -2rem;
    }

    .categoryStores {
      width: 1152px;
      padding: 1rem 2rem;
      margin: 0 -2rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .categoriesSection {
    .categoriesTabs:before {
      left: 0;
      right: 0;
    }

    .categoryStoresContainer {
      overflow-x: inherit;
    }

    .categoryStores {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      width: 100%;

      & > :nth-child(1n + 10) {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .categoriesSection {
    .categoriesTabs {
      grid-column-gap: 1rem;
    }

    .categoryStores {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      & > :nth-child(1n + 10) {
        display: flex;
      }
    }
  }
}
