@import "@styles/breakpoints.module.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.storesContainer {
  display: grid;
  grid-column-gap: 1rem;
  grid-auto-flow: column;
  position: relative;
  padding: 1rem 0 0;
}

.container.loading {
  .title > div {
    width: 10.5rem;
    height: 1.5rem;
    border-radius: 4px;
    background-color: #f4f2f7;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .store {
    min-width: 15.25;
  }
  .storesContainer {
    padding: 1rem 0 0;
  }
}

.container.rtl {
  direction: rtl;
}

@media only screen and (min-width: $breakpoint-medium) {
}

@media only screen and (min-width: $breakpoint-large) {
  .store {
    min-width: 17rem;
  }
}
