.phoneFieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dadbe0;
  direction: ltr;

  .countryCode {
    color: #9b9ca2;
  }

  .inputContainer {
    flex-grow: 1;
  }

  input {
    border: 0;
    background-color: #fff;
    color: #373946;
    font-size: 1rem;
    padding: 0.75rem;
    width: 100%;
  }
}
