@import "@styles/breakpoints.module.scss";
@import "@styles/hideScrollbar.module.scss";

.section {
  width: 100%;
}

.title {
  color: #27242c;
  font-size: 1.25rem;
  font-weight: bold;
}

.contentContainer {
  overflow-x: scroll;
  margin: 0 -1rem;
  padding: 1rem;
  display: flex;
  @include hideScrollbar;
}

.content {
  display: flex;
  width: fit-content;
}

.logoContainer {
  width: calc(100 / 360 * 100vw);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
}

.logoContainer ~ .logoContainer {
  margin-left: 0.5rem;
}

.section.rtl {
  .logoContainer ~ .logoContainer {
    margin-left: inherit;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .contentContainer {
    overflow-x: inherit;
    margin: 0;
    padding: 0;
  }

  .content {
    width: 100%;
    justify-content: flex-start;
    padding: inherit;
    padding-top: 1rem;
  }

  .logoContainer {
    width: calc(
      (100% - (7 * 0.5rem)) / 8
    ); // 7 * 0.5rem = total spacing between logos
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .logoContainer {
    width: calc(
      (100% - (7 * 1rem)) / 8
    ); // 7 * 1rem = total spacing between logos
  }

  .logoContainer ~ .logoContainer {
    margin-left: 1rem;
  }
}
