@import "@styles/breakpoints.module.scss";

.button {
  position: fixed;
  bottom: 5.5rem;
  right: 0.6rem;
  z-index: 2;
  height: 60px;
  width: 60px;
  cursor: pointer;
  box-shadow: 0 0.0625rem 0.5rem 0 rgb(55 57 70 / 8%);
  border-radius: 100px;
  overflow: hidden;
}

.rtl {
  &.button {
    right: inherit;
    left: 0.6rem;
  }
}
