@import "@styles/breakpoints.module.scss";

.container {
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.dealsContainer {
  display: grid;
  grid-auto-flow: row;
  position: relative;
}

.container.rtl {
  direction: rtl;
}

@media only screen and (min-width: $breakpoint-medium) {
  .title {
    margin-left: 0;
  }
  .dealsContainer {
    padding: 0;
  }

  .container.rtl {
    .title {
      margin-left: inherit;
      margin-right: 0;
    }
  }
}
