@import "@styles/breakpoints.module.scss";

.modalOverride {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;

  &.fullScreen {
    top: 0;
    overflow-y: auto;
    border-radius: unset;
  }
}

.closeText {
  font-size: 12px;
  position: absolute;
  padding: 1.25rem 1rem 1rem;
  top: 0;
  right: 0;
  color: #9b9ca2;
  z-index: 1;

  &.rtl {
    right: auto;
    left: 0;
  }
}

.closeIcon {
  transform: scale(1.25);
  position: absolute;
  top: -2.25rem;
  right: 0;
  fill: #ffffff;
  cursor: pointer;
}

.storeName {
  font-size: 1rem;
  line-height: 26px;
  color: #373946;
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .modalOverride {
    top: auto;
    left: auto;
    bottom: 0;
    transform: unset;
    width: 100%;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .closeIcon {
    top: 1rem;
    left: 1rem;
    transform: unset;
    right: unset;
    fill: unset;
    z-index: 1;
  }
}
