@import "@styles/breakpoints.module.scss";

@mixin skeletonDefault {
  background-color: #f4f2f7;
  border-radius: 0.25rem;
  color: #f4f2f7 !important;
}

.button {
  margin-top: auto;
  height: 2.25rem;
  width: 100%;
}

.empty {
  @include skeletonDefault();
}

.withOut {
  height: 100%;
  .cardBody {
    width: 12.5rem;
    height: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 0.375rem 0.5rem 0 rgba(55, 57, 70, 0.08);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .storeImage {
    width: 100%;
    height: 8.125rem;
    border-radius: 0.5rem 0.5rem 0 0;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .storeLogo {
    position: absolute;
    width: 3rem;
    height: 3rem;
    border: 0.25rem white solid;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.1);
    left: 0.5rem;
    bottom: 0.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    cursor: pointer;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.empty {
      cursor: auto;
      @include skeletonDefault();
    }
  }

  .cardBottom {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 100%;
    }

    button {
      margin-top: auto;
    }
  }

  .storeName {
    font-size: 1rem;
    text-align: center;
    line-height: 1.625rem;

    &.empty {
      width: 20%;
      height: 1rem;
      margin-bottom: 0.75rem;
    }
  }

  .dealName {
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    line-height: 1.375rem;
    color: #373946;

    &.empty {
      width: 40%;
      height: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .dealExpiry {
    color: #9b9ca2;
    line-height: 1.25rem;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    text-align: center;

    &.empty {
      width: 60%;
      height: 1rem;
      margin-bottom: 1rem;
    }
  }

  .cashbackText {
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: #9b9ca2;
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    text-align: center;

    .cashbackMain {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: inherit;
      color: #e75e40;
    }

    .cashbackSuffix {
      display: inline-block;
      line-height: inherit;
      margin-left: 0.375rem;
    }

    &.empty {
      width: 35%;
      height: 1rem;
    }
  }

  .visitStore {
    width: 100%;
    height: 2.25rem;
    border: 0;
    border-radius: 0.25rem;
    background-image: linear-gradient(99deg, #e75e40, #f18254 100%);
    text-align: center;
    justify-self: flex-end;
    align-self: flex-end;

    span {
      display: block;
      color: #ffffff;
      font-weight: bold;
    }
  }
}

.rtl {
  direction: rtl;

  &.withOut {
    .cashbackText {
      .cashbackSuffix {
        direction: ltr;
        margin-left: 0;
        margin-right: 0.375rem;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .withOut {
    .cardBody {
      width: 14rem;
    }

    .storeImage {
      height: 9rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .withOut {
    .cardBody {
      width: 17rem;
    }

    .storeImage {
      height: 11rem;
    }
  }
}
