.title {
  font-weight: 600;
  font-size: 16px;
}

.note {
  font-weight: 400;
  color: #27242c;
  font-size: 16px;
}

.phoneNumber {
  color: #e75e40;
  direction: ltr;
}

.buttonContainer {
  margin: 24px 0;
}

.otpTimer {
  font-size: 14px;
  color: #9b9ca2;
}

.resendOtp {
  color: #9b9ca2;
  font-size: 14px;
  font-weight: 400;

  span {
    font-weight: 700;
    color: #e75e40;
    cursor: pointer;
  }
}
