@import "@styles/breakpoints.module.scss";

.circleContainer {
  padding: calc(16 / 1440 * 100vw) calc(8 / 1440 * 100vw);
  cursor: pointer;
}

.circle {
  background-color: #dadbe0;
  box-shadow: none;
  width: calc(8 / 1440 * 100vw);
  height: calc(8 / 1440 * 100vw);
  border-radius: calc(8 / 2 / 1440 * 100vw);
  transition-duration: 0.7s;
  transition-timing-function: ease;

  &.active {
    width: calc(32 / 1440 * 100vw);
    background-color: #e75e40;
  }
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .circleContainer {
    padding: 1rem 0.5rem;
  }

  .circle {
    background-color: #dadbe0;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.125rem;

    &.active {
      width: 1.5rem;
    }
  }
}
