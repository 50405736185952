.container {
  display: flex;
  align-items: center;
  width: 100px;
  cursor: pointer;
  flex-direction: column;
  padding-bottom: 0.5rem;
  position: relative;

  img {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(12%) sepia(2%) saturate(4140%)
      hue-rotate(221deg) brightness(92%) contrast(91%);
  }

  .textContainer {
    margin-top: 4px;
    font-size: 0.75rem;
    line-height: 1.17;
    color: #27242c;
    text-align: center;
  }

  &:hover,
  &.selected {
    .textContainer {
      font-weight: bold;
      color: #e75e40;
    }

    img {
      filter: brightness(0) saturate(100%) invert(47%) sepia(82%)
        saturate(1420%) hue-rotate(333deg) brightness(92%) contrast(97%);
    }
  }

  &.selected:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    border-bottom: 2px solid #e75e40;
    width: 100%;
  }

  &.isLoading {
    img {
      // visibility: hidden;
      background-color: #f4f2f7;
      filter: brightness(0) saturate(100%) invert(91%) sepia(2%) saturate(427%)
        hue-rotate(214deg) brightness(108%) contrast(94%);
      border-radius: 4px;
    }

    .textContainer {
      width: 100%;

      &:before,
      &:after {
        content: "";
        display: inline-block;
        background-color: #f4f2f7;
        height: 10px;
        width: 52%;
      }
    }
  }
}
