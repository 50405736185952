@import "@styles/breakpoints.module.scss";

.mainBanners {
  position: relative;
  overflow: hidden;
  min-height: 22.5rem;
  width: 100%;

  .slides {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    min-height: inherit;
    height: calc(100vw / 4);

    &.animated {
      transition-duration: 0.35s;
    }
  }

  li {
    min-width: 100%;
    height: 100%;
  }

  .circles {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.prevButtonContainer,
.nextButtonContainer {
  display: none;
  position: absolute;
  top: calc(50% - (60 / 1440 * 100vw));
  z-index: 5;
  cursor: pointer;

  &:hover {
    fill: #e75e40;
  }
}

.prevButtonContainer {
  left: 1rem;

  svg {
    transform: rotate(180deg);
  }
}

.nextButtonContainer {
  right: 1rem;
}

.prevButton,
.nextButton {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
  user-select: none;

  svg {
    width: calc(60 / 1440 * 100vw);
  }
}

.mainBanners.rtl {
  direction: rtl;

  .prevButtonContainer {
    left: inherit;
    right: 1rem;
    transform: scaleX(-1);
  }

  .nextButtonContainer {
    right: inherit;
    left: 1rem;
    transform: scaleX(-1);
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .prevButtonContainer,
  .nextButtonContainer {
    display: block;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .mainBanners.rtl {
    direction: rtl;

    .prevButtonContainer {
      left: inherit;
      right: 1rem;
      transform: scaleX(-1);
    }

    .nextButtonContainer {
      right: inherit;
      left: 1rem;
      transform: scaleX(-1);
    }
  }
}

@media only screen and (max-width: $breakpoint-large - 1) {
  .mainBanners {
    min-height: 16rem;
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  .mainBanners {
    min-height: 12rem;
  }
}
