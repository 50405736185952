@import "@styles/breakpoints.module.scss";

@mixin grey-box {
  background: #f4f2f7;
  height: 0.75rem;
  border-radius: 0.25rem;
  color: transparent;
}

.container {
  position: relative;
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #f4f2f7;
  padding-right: 1.5rem;
}

.storeLogoContainer {
  margin-right: 1rem;
  position: relative;
  width: 3.25rem;
  height: 3.25rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  z-index: 1;
  pointer-events: none;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  pointer-events: none;

  .storeName {
    font-size: 16px;
    color: #373946;
  }
  .cashbackText {
    font-size: 14px;
    font-weight: bold;
    color: #e75e40;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #27242c;
    margin-bottom: 0.5rem;
  }

  .expiry {
    font-size: 12px;
    color: #9b9ca2;
  }

  .expiry {
    margin-top: 0.5rem;
  }

  .previewButtonContainer {
    margin-top: 0.5rem;
    max-width: 12rem;
  }
}

.cta {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  .chevronContainer,
  a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  .chevron {
    fill: #e75e40;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
  }
}

// https://css-tricks.com/line-clampin/
.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.container.rtl {
  direction: rtl;
  padding-right: 0;
  padding-left: 1.5rem;

  .storeLogoContainer {
    margin-right: inherit;
    margin-left: 1rem;
  }

  .cta {
    .chevron {
      transform: rotate(90deg);
    }
  }
}

.container.loading {
  .storeLogoContainer {
    padding: 0;
    box-shadow: none;
  }
  .storeLogoSkeleton {
    @include grey-box;
    width: 3.25rem;
    height: 3.25rem;
  }
  .storeName {
    @include grey-box;
    width: 4.5rem;
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  .skeletonTexts {
    display: grid;
    column-gap: 0.25rem;
    grid-template-columns: 2.5rem 5rem 3.75rem;
    > div {
      @include grey-box;
      height: 1rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }

  .storeLogoContainer {
    width: 4rem;
    height: 4rem;
    pointer-events: inherit;
  }

  .main {
    pointer-events: inherit;
  }

  .container.loading {
    .storeLogoSkeleton {
      @include grey-box;
      width: 4rem;
      height: 4rem;
    }
  }

  .cta {
    position: relative;
    top: unset;
    height: inherit;
    padding-left: 1rem;
    width: fit-content;

    // this is to make all the buttons have equal widths (based on the longest text)
    [data-sibling-text] {
      display: flex;
      flex-direction: column;

      &:after {
        content: attr(data-sibling-text);
        height: 0;
        overflow: hidden;
      }
    }
  }

  .container.rtl {
    padding-right: 0;
    padding-left: 0;

    .cta {
      padding-left: 0;
      padding-right: 1rem;
    }
  }
}
