@import "@styles/breakpoints.module.scss";
@import "@styles/ellipsis.module.scss";

.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0.75rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 100%;

  &:hover {
    transform: scale(1.04);
  }

  .logoContainer {
    height: 56px;
    width: 56px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 0.75rem;

    img {
      width: 56px;
      height: 56px;
    }

    &.empty {
      background-color: #f4f2f7;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    margin: -5px 0;
    width: calc(100% - 56px - 0.75rem);

    .name {
      font-size: 1rem;
      color: #373946;
      @include ellipsis;

      &.empty {
        width: 60%;
        height: 16px;
        margin-top: 3px;
        margin-bottom: 3px;
        border-radius: 4px;
        background-color: #f4f2f7;
      }
    }

    .cashbackMain {
      font-size: 0.875rem;
      font-weight: bold;
      color: #e75e40;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 0.375rem;
      display: block;
      @include ellipsis;

      &.empty {
        background-color: #f4f2f7;
        display: inline-flex;
        margin-top: 0.5rem;
        width: 75%;
        height: 12px;
        justify-content: space-between;

        &:after,
        &:before {
          content: "";
          display: block;
          width: calc(75% - 0.375rem);
          background-color: #f4f2f7;
          border-radius: 4px;
        }

        &:before {
          width: 25%;
        }
      }
    }

    .cashbackSuffix {
      font-size: 0.75rem;
      color: #9b9ca2;
      font-weight: normal;

      &.empty {
        margin-top: 0.5rem;
        width: 50%;
        height: 10px;
        border-radius: 4px;
        background-color: #f4f2f7;
      }
    }
  }
}

.rtl {
  direction: rtl;

  &.container {
    > div:first-of-type {
      margin-right: inherit;
      margin-left: 0.75rem;
    }

    .logoContainer {
      margin-right: inherit;
      margin-left: 0.75rem;
    }

    .content {
      .cashbackMain {
        margin-right: inherit;
        margin-left: 0.375rem;
      }

      .cashbackSuffix {
        direction: ltr;
        text-align: right;
      }
    }
  }
}
