@import "@styles/breakpoints.module.scss";

.container {
  height: 26rem;
  width: 17.75rem;
  top: 4.75rem;
  position: sticky;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  padding: 2rem;
  grid-gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 0.5rem 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.logo {
  height: 4rem;
  width: 4rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.04);
  }
}

.rtl {
  direction: rtl;
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .container {
    display: none;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .container {
    height: 25rem;
    width: 19rem;
  }

  .logo {
    height: 4.3125rem;
    width: 4.3125rem;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .container {
    height: 30rem;
    width: 23rem;
  }

  .logo {
    height: 5.6875rem;
    width: 5.6875rem;
  }
}
