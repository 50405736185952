@import "@styles/breakpoints.module.scss";

.title {
  color: #27242c;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.bannersContainer {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.banner {
  display: flex;

  .imageContainer {
    border-radius: 0.5rem;
    width: 150px;
    height: 243px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0.0625rem 0.5rem 0 rgb(55 57 70 / 8%);

    img {
      display: block;
      height: 100%;
      max-width: 150px;
    }
  }
}

.loading {
  .title {
    background-color: #f4f2f7;
    border-radius: 0.5rem;
    width: 15rem;
    height: 1.875rem;
  }

  .banner .imageContainer {
    background-color: #f4f2f7;
  }
}

.section.rtl {
  direction: rtl;
}

@media only screen and (min-width: $breakpoint-medium) {
  .bannersContainer {
    grid-column-gap: 0.58rem;
  }

  .banner {
    .imageContainer {
      width: 150px;
      height: 243px;
      box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);

      img {
        max-width: 150px;
        max-height: 243px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .bannersContainer {
    grid-column-gap: 1rem;
  }
  .banner {
    .imageContainer {
      width: 176px;
      height: 284px;

      img {
        max-width: 176px;
        max-height: 284px;
      }
    }
  }
}
