@import "@styles/breakpoints.module.scss";

.dealCollectionContainer {
  &.rtl {
    direction: rtl;
  }
}

.dealCollectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    margin: 0;
    color: #27242c;
  }

  .seeAll {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #e75e40;
  }
}

.gridContainer {
  height: 100%;
  display: flex;
  display: grid;
  grid: auto / auto-flow max-content;
  grid-gap: 1rem;
  align-items: center;
}

@media only screen and (max-width: $breakpoint-small) {
  .gridContainer {
    grid-gap: 0.5rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
}

@media only screen and (min-width: $breakpoint-large) {
}
