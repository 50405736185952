@import "@styles/breakpoints.module.scss";
@import "@styles/hideScrollbar.module.scss";

.carouselContainer {
  display: block;
  position: relative;
  width: 100%;
}

.carousel {
  display: flex;
  overflow: hidden;
  padding: 1rem 0.5rem;
  margin: -1rem -0.5rem;
  @include hideScrollbar;
}

.isMobile .carousel {
  padding: 1rem 0;
  margin: -1rem -1rem;

  & > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.scrollLeft,
.scrollRight {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  top: calc(50% - 20px);
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(55, 57, 70, 0.2);
  user-select: none;

  &:hover {
    fill: #e75e40;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.scrollLeft {
  left: -20px; //half of width

  svg {
    transform: rotate(90deg);
  }
}

.scrollRight {
  right: -20px; //half of width

  svg {
    transform: rotate(-90deg);
  }
}

.scrollLeft.hidden {
  display: none;
}

.scrollRight.hidden {
  display: none;
}

.carouselContainer.rtl {
  .scrollLeft {
    left: inherit;
    right: -20px;

    svg {
      transform: rotate(-90deg);
    }
  }

  .scrollRight {
    right: inherit;
    left: -20px;

    svg {
      transform: rotate(90deg);
    }
  }
}

@media only screen and (max-width: ($breakpoint-medium - 1)) {
  .carousel {
    padding: 1rem 0;
    margin: -1rem -2rem;
    overflow-x: auto;

    & > div {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .scrollLeft,
  .scrollRight {
    display: none;
  }
}
