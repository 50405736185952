@import "@styles/breakpoints.module.scss";

main.container {
  display: block;
  width: 100%;

  .greyBackground {
    background-color: #f4f4ff;
    padding: 2rem 0 0;
    margin-bottom: 3rem;
    display: flow-root;
  }

  .wrap {
    padding: 0 0.5rem;
    margin: 0 auto;
    max-width: 45rem;
    position: relative;

    &:not(.last) > * {
      margin-bottom: 3rem;
    }

    &.twoColumns {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 2rem;
    }
  }

  .phoneVerificationModal {
    top: 10%;
    left: calc(50% - 29rem / 2);
    border-radius: 8px;
    width: 29rem;
    min-height: 25rem;
    background: #fff;
  }

  .closeIcon {
    fill: #fff;
    position: absolute;
    top: -2.25rem;
    right: 0;
    cursor: pointer;
  }

  &.isMobile {
    .wrap {
      min-width: 22.5rem;
      padding: 0 1rem;

      .greyBackground {
        margin-bottom: 2.5rem;
      }

      & > * {
        margin-bottom: 2.5rem;
      }

      &.twoColumns {
        display: block;
      }

      &.accountSummaryContainer {
        padding-top: 1rem;

        & > * {
          margin-bottom: 1rem;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 174px;
          background: linear-gradient(
            102deg,
            #2a2961,
            #321985 80%,
            #321985 80%
          );
          z-index: -1;
          top: -100px;
          left: 0;
        }
      }
    }

    .heroContainer {
      margin-bottom: 1rem;
    }

    .loadDealsContainer {
      button {
        width: 100%;
      }
    }

    .phoneVerificationModal {
      top: unset;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      border-radius: 8px 8px 0 0;
    }

    .closeIcon {
      fill: unset;
      top: 1rem;
      right: 1rem;
    }
  }

  .loadDealsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    img {
      height: 60px;
      width: 60px;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  main.container {
    .wrap {
      min-width: 48rem;
      max-width: 100%;
      padding: 0 2rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  main.container {
    .wrap {
      max-width: 60rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  main.container {
    .wrap {
      max-width: 72rem;
    }
  }
}

main.container.rtl {
  direction: rtl;

  .closeIcon {
    right: unset;
    left: 0;
  }

  &.isMobile {
    .closeIcon {
      right: unset;
      left: 1rem;
    }
  }
}
