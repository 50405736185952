@import "@styles/breakpoints.module.scss";

.container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: fill;
}

.imageLoader {
  width: 100%;
  height: 100%;
  background-color: #f4f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;

  .shopcashLogo {
    width: 12.5rem;
    height: 2.5rem;
  }
}

@media only screen and (min-width: $breakpoint-small) {
  .image {
    border-radius: inherit;
  }

  .imageLoader {
    border-radius: inherit;

    .shopcashLogo {
      width: (200/1440 * 100%);
    }
  }
}
