@import "@styles/breakpoints.module.scss";

@mixin skeletonDefault {
  background-color: #f4f2f7;
  border-radius: 0.25rem;
  color: #f4f2f7 !important;
}

.button {
  margin-top: auto;
  height: 2.25rem;
  width: 100%;
}

.card {
  height: 100%;
  padding-top: 1.5rem;
}

.cardBody {
  width: 12.5rem;
  height: 100%;
  padding: 4rem 1rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.0625rem 0.5rem 0 rgba(55, 57, 70, 0.08);
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    width: 100%;
  }
}

.storeLogoContainer {
  position: absolute;
  top: -1.5rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.storeLogo {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 1rem 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
}

.storeName {
  text-align: center;
  line-height: 1.625rem;
}

.dealName {
  text-align: center;
  font-weight: bold;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
  color: #373946;
  display: block;
}

.dealExpiry {
  color: #9b9ca2;
  text-align: center;
  line-height: 1.25rem;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.cashbackText {
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #9b9ca2;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;

  .cashbackMain {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: inherit;
    color: #e75e40;
  }

  .cashbackSuffix {
    display: inline-block;
    line-height: inherit;
    margin-left: 0.375rem;
  }
}

.empty {
  .storeLogo {
    @include skeletonDefault();
    cursor: auto;
  }

  .storeName {
    @include skeletonDefault();
    width: 20%;
    height: 1rem;
    margin-bottom: 0.625rem;
  }

  .dealName {
    @include skeletonDefault();
    display: flex;
    background-color: #ffffff;
    justify-content: center;

    &::after,
    &::before {
      @include skeletonDefault();
      display: block;
      content: "";
      width: 50%;
      height: 1rem;
    }

    &::after {
      margin-left: 0.5rem;
      width: 30%;
    }
  }

  .dealExpiry {
    @include skeletonDefault();
    width: 80%;
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  .cashbackText {
    @include skeletonDefault();
    width: 70%;
    height: 1rem;
  }

  .button {
    @include skeletonDefault();
  }
}

.rtl {
  direction: rtl;

  .cashbackText .cashbackSuffix {
    direction: ltr;
    margin-left: 0;
    margin-right: 0.375rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .cardBody {
    width: 14rem;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .cardBody {
    width: 13.375rem;
  }
}
