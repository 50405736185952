.contentContainer {
  padding: 1rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.875rem rgba(42, 41, 97, 0.08);
  min-height: 7.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .howToEarnLink {
    font-weight: bold;
    font-size: 0.875rem;
    color: #e75e40;
  }

  .empty {
    .description {
      background: #f4f2f7;
      color: #f4f2f7;
      border-radius: 4px;
    }

    .button {
      background: #f4f2f7;
      height: 3rem;
      border-radius: 4px;
    }
  }

  .description {
    font-size: 14px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .walletSummary {
    display: flex;
    width: 100%;

    & > div {
      width: 50%;

      &.balanceAmount {
        text-align: end;
        font-weight: bold;
        font-size: 1.5rem;

        sup {
          font-size: 0.75rem;
          color: #9b9ca2;
          margin-right: 0.2rem;
        }
      }
    }

    .label {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 0.875rem;

      span {
        font-weight: normal;
        font-size: 0.75rem;
      }
    }
  }

  .userActionButtons {
    display: grid;
    grid-template-columns: 1fr 1.45fr 1fr;
    margin: 1rem -1rem -1rem;

    & > a {
      font-size: 0.75rem;
      border-top: 1px solid #f2f2f2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 0.75rem 0.75rem;
      text-align: center;

      .icon {
        transform: scale(1.33);
        margin-bottom: 0.5rem;
      }
    }

    a + a {
      border-left: 1px solid #f2f2f2;
    }
  }
}

.rtl {
  .contentContainer {
    .userActionButtons {
      a + a {
        border-left: none;
        border-right: 1px solid #f2f2f2;
      }
    }
  }
}
