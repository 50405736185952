@import "@styles/breakpoints.module.scss";
@import "@styles/ellipsis.module.scss";

@mixin grey-box {
  background: #f4f2f7;
  height: 0.75rem;
  border-radius: 0.25rem;
  color: transparent;
}

.container {
  display: block;
  max-width: 17rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
}

.cardImageContainer {
  display: flex;
  width: 16.25rem;
  height: 10.5rem;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  &.empty {
    @include grey-box;
    height: 10.5rem;
    background-color: #f4f2f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    .shopcashLogo {
      object-fit: contain;
      width: 7.5rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.detailsContainer {
  display: flex;
  font-size: 1rem;
  width: 16.25rem;
  margin-top: 0.5rem;
}

.logoContainer {
  margin-right: 0.75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgb(0 0 0 / 10%);
  padding: 0.25rem;
  > div:first-of-type {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.texts {
  width: calc(100% - 3rem - 0.75rem);
}

.name {
  font-size: 1rem;
  color: #373946;
  @include ellipsis;
}

.cashbackText {
  font-size: 14px;
  color: #e75e40;
  font-weight: bold;
}

.cashbackTextSuffix {
  font-size: 0.75rem;
  color: #9b9ca2;
}

.container.empty {
  .logoContainer {
    @include grey-box;
    width: 3rem;
    height: 3rem;
  }

  .name {
    @include grey-box;
    width: 3rem;
    height: 1rem;
  }

  .cashbackText {
    @include grey-box;
    width: 10rem;
    height: 1rem;
    margin-top: 0.5rem;
  }

  .cashbackTextSuffix {
    @include grey-box;
    width: 5.5rem;
    height: 0.75rem;
    margin-top: 0.5rem;
  }
}

.container.rtl {
  .detailsContainer {
    direction: rtl;
  }

  .logoContainer {
    margin-right: inherit;
    margin-left: 0.75rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .cardImageContainer {
    width: 14rem;
    height: 9rem;

    &.empty {
      height: 9rem;
    }
  }

  .detailsContainer {
    width: 14rem;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .cardImageContainer {
    width: 17rem;
    height: 11rem;
    &.empty {
      height: 11rem;
    }
  }

  .detailsContainer {
    width: 17rem;
  }
}
