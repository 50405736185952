@import "@styles/breakpoints.module.scss";

@mixin skeletonDefault {
  background-color: #f4f2f7;
  border-radius: 0.25rem;
  color: #f4f2f7 !important;
}

.button {
  margin-top: auto;
  height: 2.25rem;
  width: 100%;
}

.cardBody {
  width: 12.5rem;
  height: 14.0625rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.04);
  }
}

.dealTop {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dealName {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.375rem;
  color: #27242c;
  margin-bottom: 0.5rem;
}

.storeDetails {
  border-top: 0.0625rem solid #f4f2f7;
  padding-top: 1rem;
  display: flex;
}

.storeLogo {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.storeName {
  font-size: 1rem;
  color: #373946;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.empty {
  .dealName {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;

    &::after,
    &::before {
      @include skeletonDefault();
      display: block;
      content: "";
      width: 100%;
      height: 1rem;
    }

    &::after {
      width: 80%;
      margin-top: 0.5rem;
    }
  }

  .storeLogo {
    @include skeletonDefault();
    cursor: auto;
  }

  .storeName {
    flex: 1;
    background-color: #fff;

    &::after,
    &::before {
      @include skeletonDefault();
      display: block;
      content: "";
      width: 40%;
      height: 1rem;
    }

    &::after {
      margin-top: 0.5rem;
      width: 60%;
    }
  }

  .button {
    @include skeletonDefault();
  }
}

.rtl {
  direction: rtl;

  .storeLogo {
    margin-right: 0;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .cardBody {
    width: 14rem;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .cardBody {
    width: 13.4rem;
  }
}
