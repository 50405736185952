@import "@styles/breakpoints.module.scss";
@import "@styles/hideScrollbar.module.scss";

.section {
  .title {
    color: #27242c;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
    }
  }

  .seeAll {
    color: #e75e40;
    cursor: pointer;
    font-size: 1rem;
  }
}

.trendingStoresContainer {
  overflow-x: auto;
  @include hideScrollbar;

  &.isMobile {
    padding: 0 1rem;
    margin: 0 -1rem;
  }
}

.trendingStores {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &.isMobile {
    // 4 store cards(310px each) + grid gap (1rem each) + side paddings (1rem each)
    width: calc((310px * 4) + (1rem * 5));

    padding: 1rem 1rem;
    margin: 0 -1rem;
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  .trendingStoresContainer {
    padding: 1rem 2rem;
    margin: 0 -2rem;
  }

  .trendingStores {
    padding: 1rem 2rem;
    margin: 0 -2rem;
    width: 72rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .trendingStoresContainer {
    overflow-x: inherit;
  }

  .trendingStores {
    grid-template-columns: repeat(3, minmax(0, 3fr));

    & > :nth-child(1n + 10) {
      display: none;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .trendingStores {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    & > :nth-child(1n + 10) {
      display: flex;
    }
  }
}
