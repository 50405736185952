@import "@styles/breakpoints.module.scss";

.container {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  button {
    margin-top: auto;
  }
}

.storeLogo {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(55, 57, 70, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 1rem;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.dealName {
  font-size: 1rem;
  font-weight: bold;
  color: #373946;
  margin-bottom: 1rem;
  text-align: center;
}

.dealCouponContainer {
  min-width: 10rem;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 1px #e85d40;
  margin-bottom: 1.5rem;
  position: relative;
  cursor: pointer;
  padding: 0 2.5rem;
}

.dealCoupon {
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.18px;
  color: #e75e40;
}

.copyIcon {
  position: absolute;
  right: 1rem;
  fill: #9b9ca2;
  opacity: 0;
  transition: opacity 0.2s ease-in;

  &.show {
    opacity: 1;
  }
}

.dealCouponContainer:hover .copyIcon {
  fill: #e75e40;
}

.codeCopiedText {
  font-size: 0.625rem;
  color: #e75e40;
  position: absolute;
  top: -0.5rem;
  padding: 0 0.5rem;
  background-color: #fff;
}

.note {
  font-size: 0.75rem;
  color: #27242c;
  margin-bottom: 1.5rem;
}

.viewMore {
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1.75rem;
  margin-bottom: 0.5rem;
  color: #e75e40;
}

@media only screen and (min-width: $breakpoint-medium) {
  .container {
    width: 407px;
    padding: 2rem 1.5rem 1.5rem;
  }
}

.rtl.container {
  direction: rtl;

  .copyIcon {
    right: inherit;
    left: 1rem;
  }
}
