@import "@styles/breakpoints.module.scss";

.modal {
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8rem auto 0;
  max-width: 783px;
  max-width: calc(100% - 2rem);
}

.educationVideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;

  .title {
    color: #27242c;
    margin-bottom: 1rem;
    font-size: 29px;
    font-weight: 600;
    text-align: center;
  }

  .iframeWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* aspect ratio hack: https://css-tricks.com/aspect-ratio-boxes/ */
    margin-bottom: 1.5rem;
  }

  iframe {
    position: absolute;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
  }

  .buttonContainer {
    width: 328px;
    padding: 0 1rem;
  }

  &.isMobile {
    .title {
      font-size: 20px;
    }

    .buttonContainer {
      width: 100%;
    }
  }
}

@media (orientation: landscape) {
  .modal {
    max-width: 328px;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-small + 1) {
  .modal {
    max-width: 783px;
    margin: 8rem auto 0;
  }

  .educationVideo {
    padding: 2rem;
  }
}
