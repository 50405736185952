@import "@styles/breakpoints.module.scss";

.mainBanners {
  overflow: hidden;
  min-height: 10rem;

  .slides {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: calc(320 / 360 / 2 * 100vw);
    min-height: inherit;

    &.animated {
      transition-duration: 0.35s;
    }

    &.loading {
      width: calc(100vw - 32px);
      margin-left: 1rem;
    }
  }

  .slide {
    height: 100%;
    width: calc(100vw - 32px);
  }

  .slide ~ .slide {
    margin-left: 0.5rem;
  }

  .slide:first-child {
    margin-left: 1rem;
  }

  .slide:last-child {
    margin-right: 1rem;
  }

  .circles {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mainBanners.rtl {
  direction: rtl;

  .slide ~ .slide {
    margin-left: inherit;
    margin-right: 0.5rem;
  }

  .slide:first-child {
    margin-left: inherit;
    margin-right: 1rem;
  }

  .slide:last-child {
    margin-left: inherit;
  }
}
